.page-tokens {

    .canvas-placeholder {
        position: absolute;
        top: 5rem;
        left: 50%;
    }

    .header-nav {
        background: #76441388;
    }

    .container {
        position: relative;
    }

    .canvas-placeholder-1 {
        left: 25%;
    }

    .canvas-placeholder-2 {
        left: 72%;
    }

    .canvas-placeholder-3 {
        left: 25%;
    }

    .coin-content a {
        color: currentColor;
        text-decoration: underline;
        text-decoration-color: currentColor;
    }

    .coin-list {
        display: block;
        list-style: none;
        margin: 2em 0;
        padding: 0;
        font-size: 1.1em;

        li {
            display: block;
            margin-bottom: 1em;
            padding-left: 1.6em;
            position: relative;

            &:before {
                content: '';
                display: block;
                width: 6px;
                height: 6px;
                background: currentColor;
                position: absolute;
                left: 0;
                top: 0.55em;
                border-radius: 50%;
            }
        }
    }

    @media (max-width: 1199px) {
        .canvas-placeholder {
            top: 4rem;
        }
    }

    @media (max-width: 991px) {
        .canvas-placeholder {
            left: 83%;
        }
    }

    @media (max-width: 767px) {
        .canvas-placeholder {
            left: 50%;
            top: 5rem;
        }

        .coin-content {
            margin-top: 15rem;
        }
    }
}

html.desktop.page-tokens .coin-content a:hover {
    text-decoration-color: transparent;
}
