.page-srilanka {

    .header-nav {
        background-color: #76441388;
    }

    @media (min-width: 768px) and (max-width: 1199px) {
        .display-2 {
            font-size: 62px;
        }
    }
}
