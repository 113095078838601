.page-validator {

    .header-nav {
        background-color: #2c2416cc !important;
    }

    .canvas-placeholder {
        position: relative;
        width: 100%;
        flex-shrink: 0;
    }

    .container {
        position: relative;
    }

    .canvas-placeholder-1 > div {
        position: absolute;
        bottom: -7rem;
        left: 20%;
        outline: 2px solid red;
    }

    .canvas-placeholder-2 > div {
        position: absolute;
        bottom: -7rem;
        left: 85%;
        outline: 2px solid red;
    }

    .canvas-placeholder-3 > div {
        position: absolute;
        bottom: -7.5rem;
        left: 73%;
    }

    .canvas-placeholder-4 > div {
        position: absolute;
        bottom: -8rem;
        left: 23%;
    }

    @media (max-width: 1199px) {
        .canvas-placeholder-1 {
            margin-bottom: -4rem;
        }

        .canvas-placeholder-1>div {
            bottom: -4rem;
        }
    }

    @media (max-width: 991px) {
        .canvas-placeholder-1 {
            margin-top: 15rem;
            margin-bottom: -10.5rem;
        }

        .canvas-placeholder-1 > div {
            bottom: 8rem;
        }

        .canvas-placeholder-3 > div {
            left: 85%;
            bottom: -3rem;
        }

        .canvas-placeholder-2 > div,
        .canvas-placeholder-4 > div {
            bottom: -3rem;
        }
    }

    @media (max-width: 767px) {
        .canvas-placeholder>div {
            left: 40%;
        }

        .canvas-placeholder-1 {
            margin-bottom: -10rem;
        }

        .canvas-placeholder-1>div {
            left: 37%;
        }

        .canvas-placeholder-2 {
            margin-top: 6rem;
            margin-bottom: 8rem;
        }

        .canvas-placeholder-2>div {
            left: 50%;
        }

        .canvas-placeholder-3 {
            margin-top: 10rem;
            margin-bottom: -1rem;
        }

        .canvas-placeholder-3>div {
            left: 37%;
            bottom: 0;
        }

        .canvas-placeholder-4 {
            margin-top: 5rem;
            margin-bottom: 4rem;
        }

        .canvas-placeholder-4>div {
            left: 45%;
        }
    }

    @media (min-width: 768px) and (max-width: 1199px) {
        .display-2 {
            font-size: 62px;
        }
    }
}
