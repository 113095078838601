// dat.gui
.dg.ac {
    z-index: 9 !important;
}

.canvas-coin-wrap {
    width: 0;
    height: 0;
    position: absolute;
}

.canvas-coin-container {
    position: absolute;
    transform: translate(-50%, -50%);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    width: 420px;
    height: 420px;

    @media (max-width: 1399px) {
        width: 350px;
        height: 350px;
    }

    @media (max-width: 1199px) {
        width: 320px;
        height: 320px;
    }

    @media (max-width: 999px) {
        width: 270px;
        height: 270px;
    }

    @media (max-width: 767px) {
        width: 350px;
        height: 350px;
    }
}

.canvas-coin {
    display: block;
}

.canvas-bg {
    position: fixed;
    position: fixed;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    z-index: -1;

    @media (max-width: 990px) {
        left: -100% !important;
        width: 300% !important;
    }
}

.multi-canvas-container {
    position: relative;
    overflow: hidden;
}

.canvas-multi {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    outline: 2px solid green;
    padding: 0;
}
