.dg.ac {
  z-index: 9 !important;
}
.canvas-coin-wrap {
  width: 0;
  height: 0;
  position: absolute;
}
.canvas-coin-container {
  position: absolute;
  transform: translate(-50%, -50%);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 420px;
  height: 420px;
}
@media (max-width: 1399px) {
  .canvas-coin-container {
    width: 350px;
    height: 350px;
  }
}
@media (max-width: 1199px) {
  .canvas-coin-container {
    width: 320px;
    height: 320px;
  }
}
@media (max-width: 999px) {
  .canvas-coin-container {
    width: 270px;
    height: 270px;
  }
}
@media (max-width: 767px) {
  .canvas-coin-container {
    width: 350px;
    height: 350px;
  }
}
.canvas-coin {
  display: block;
}
.canvas-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  z-index: -1;
}
@media (max-width: 990px) {
  .canvas-bg {
    left: -100% !important;
    width: 300% !important;
  }
}
.multi-canvas-container {
  position: relative;
  overflow: hidden;
}
.canvas-multi {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  outline: 2px solid green;
  padding: 0;
}
.hover-dropdown {
  position: relative;
}
.hover-dropdown > span {
  display: block;
  position: relative;
  z-index: 2;
  cursor: default;
}
.hover-dropdown__inner {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 100%;
  margin-top: -1px;
  text-align: left;
  padding: 25px 25px 16px 27px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  transform: translateX(-50%) scale(0.9);
  transform-origin: top;
  /* will-change: transform; */
  box-sizing: border-box;
  color: #fff;
  white-space: nowrap;
}
html.desktop .hover-dropdown:hover .hover-dropdown__inner,
html.mobile .hover-dropdown._is-active .hover-dropdown__inner {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) scale(1);
}
.hover-dropdown__inner:before {
  content: '';
  display: block;
  position: absolute;
  z-index: 0;
  left: 0;
  top: 5px;
  right: 0;
  bottom: 0;
  background: #1118;
  border-radius: 15px;
}
.hover-dropdown__inner a {
  display: inline-block;
  vertical-align: top;
  color: #fff;
  white-space: nowrap;
  font-size: 0.9em;
  position: relative;
  z-index: 1;
}
.hover-dropdown__inner > i {
  display: inline-block;
  vertical-align: top;
  height: 1em;
  border-left: 1px solid #fff;
  opacity: 0.5;
  position: relative;
  top: 0.2em;
  z-index: 1;
  margin: 0 0.75em;
}
.header-nav a {
  white-space: nowrap;
}
.header-nav a:hover {
  opacity: 0.8 !important;
}
@media (max-width: 1199px) {
  .header-nav__menu {
    position: relative;
    left: -10px;
  }
}
.disclaimer {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  z-index: 4;
  box-sizing: border-box;
  backdrop-filter: blur(5px);
}
.disclaimer__inner {
  text-align: center;
  padding: 38px 0 35px 0;
  max-width: 90%;
}
.disclaimer__inner p {
  margin: 1em 0;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: 0.01em;
}
.disclaimer__inner p:first-child {
  margin-top: 0;
}
.disclaimer__inner p:last-child {
  margin-bottom: 0;
}
.disclaimer__agree-btn {
  margin-top: 25px;
}
@media (max-width: 500px) {
  .disclaimer__agree-btn {
    font-size: 1rem;
  }
}
.page-main .header-nav {
  background-color: #f968;
}
.page-srilanka .header-nav {
  background-color: #76441388;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .page-srilanka .display-2 {
    font-size: 62px;
  }
}
.page-validator .header-nav {
  background-color: #2c2416cc !important;
}
.page-validator .canvas-placeholder {
  position: relative;
  width: 100%;
  flex-shrink: 0;
}
.page-validator .container {
  position: relative;
}
.page-validator .canvas-placeholder-1 > div {
  position: absolute;
  bottom: -7rem;
  left: 20%;
  outline: 2px solid red;
}
.page-validator .canvas-placeholder-2 > div {
  position: absolute;
  bottom: -7rem;
  left: 85%;
  outline: 2px solid red;
}
.page-validator .canvas-placeholder-3 > div {
  position: absolute;
  bottom: -7.5rem;
  left: 73%;
}
.page-validator .canvas-placeholder-4 > div {
  position: absolute;
  bottom: -8rem;
  left: 23%;
}
@media (max-width: 1199px) {
  .page-validator .canvas-placeholder-1 {
    margin-bottom: -4rem;
  }
  .page-validator .canvas-placeholder-1 > div {
    bottom: -4rem;
  }
}
@media (max-width: 991px) {
  .page-validator .canvas-placeholder-1 {
    margin-top: 15rem;
    margin-bottom: -10.5rem;
  }
  .page-validator .canvas-placeholder-1 > div {
    bottom: 8rem;
  }
  .page-validator .canvas-placeholder-3 > div {
    left: 85%;
    bottom: -3rem;
  }
  .page-validator .canvas-placeholder-2 > div,
  .page-validator .canvas-placeholder-4 > div {
    bottom: -3rem;
  }
}
@media (max-width: 767px) {
  .page-validator .canvas-placeholder > div {
    left: 40%;
  }
  .page-validator .canvas-placeholder-1 {
    margin-bottom: -10rem;
  }
  .page-validator .canvas-placeholder-1 > div {
    left: 37%;
  }
  .page-validator .canvas-placeholder-2 {
    margin-top: 6rem;
    margin-bottom: 8rem;
  }
  .page-validator .canvas-placeholder-2 > div {
    left: 50%;
  }
  .page-validator .canvas-placeholder-3 {
    margin-top: 10rem;
    margin-bottom: -1rem;
  }
  .page-validator .canvas-placeholder-3 > div {
    left: 37%;
    bottom: 0;
  }
  .page-validator .canvas-placeholder-4 {
    margin-top: 5rem;
    margin-bottom: 4rem;
  }
  .page-validator .canvas-placeholder-4 > div {
    left: 45%;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .page-validator .display-2 {
    font-size: 62px;
  }
}
.page-tokens .canvas-placeholder {
  position: absolute;
  top: 5rem;
  left: 50%;
}
.page-tokens .header-nav {
  background: #76441388;
}
.page-tokens .container {
  position: relative;
}
.page-tokens .canvas-placeholder-1 {
  left: 25%;
}
.page-tokens .canvas-placeholder-2 {
  left: 72%;
}
.page-tokens .canvas-placeholder-3 {
  left: 25%;
}
.page-tokens .coin-content a {
  color: currentColor;
  text-decoration: underline;
  text-decoration-color: currentColor;
}
.page-tokens .coin-list {
  display: block;
  list-style: none;
  margin: 2em 0;
  padding: 0;
  font-size: 1.1em;
}
.page-tokens .coin-list li {
  display: block;
  margin-bottom: 1em;
  padding-left: 1.6em;
  position: relative;
}
.page-tokens .coin-list li:before {
  content: '';
  display: block;
  width: 6px;
  height: 6px;
  background: currentColor;
  position: absolute;
  left: 0;
  top: 0.55em;
  border-radius: 50%;
}
@media (max-width: 1199px) {
  .page-tokens .canvas-placeholder {
    top: 4rem;
  }
}
@media (max-width: 991px) {
  .page-tokens .canvas-placeholder {
    left: 83%;
  }
}
@media (max-width: 767px) {
  .page-tokens .canvas-placeholder {
    left: 50%;
    top: 5rem;
  }
  .page-tokens .coin-content {
    margin-top: 15rem;
  }
}
html.desktop.page-tokens .coin-content a:hover {
  text-decoration-color: transparent;
}
