.disclaimer {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
    background: rgba(255,255,255,0.9);
    z-index: 4;
    box-sizing: border-box;
    backdrop-filter: blur(5px);
}

.disclaimer__inner {
    text-align: center;
    padding: 38px 0 35px 0;
    max-width: 90%;

    p {
        margin: 1em 0;
        font-size: 16px;
        line-height: 130%;
        letter-spacing: 0.01em;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.disclaimer__agree-btn {
    margin-top: 25px;

    @media (max-width: 500px) {
        font-size: 1rem;
    }
}
