.header-nav {
    a {
        white-space: nowrap;

        &:hover {
            opacity: 0.8 !important;
        }
    }
}


@media (max-width: 1199px) {

    .header-nav__menu {
        position: relative;
        left: -10px;
    }
}
