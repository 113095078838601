.hover-dropdown {
    position: relative;
}

.hover-dropdown>span {
    display: block;
    position: relative;
    z-index: 2;
    cursor: default;
}

.hover-dropdown__inner {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 100%;
    margin-top: -1px;
    text-align: left;
    padding: 25px 25px 16px 27px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease;
    transform: translateX(-50%) scale(0.9);
    transform-origin: top;
    /* will-change: transform; */
    box-sizing: border-box;
    color: #fff;
    white-space: nowrap;
}

html.desktop .hover-dropdown:hover .hover-dropdown__inner,
html.mobile .hover-dropdown._is-active .hover-dropdown__inner {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) scale(1);
}

.hover-dropdown__inner:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 0;
    left: 0;
    top: 5px;
    right: 0;
    bottom: 0;
    background: #1118;
    border-radius: 15px;
}

.hover-dropdown__inner a {
    display: inline-block;
    vertical-align: top;
    color: #fff;
    white-space: nowrap;
    font-size: 0.9em;
    position: relative;
    z-index: 1;
}

.hover-dropdown__inner>i {
    display: inline-block;
    vertical-align: top;
    height: 1em;
    border-left: 1px solid #fff;
    opacity: 0.5;
    position: relative;
    top: 0.2em;
    z-index: 1;
    margin: 0 0.75em;
}
